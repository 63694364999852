export default function({ route }) {
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  // Save utm to localStorage
  if (route.query && !localStorage.getItem('checkedUtm')){
      Object.entries(route.query)
        .filter(([q, v]) => v && utmKeys.some((utmKey) => utmKey === q))
        .forEach(([q, v]) => localStorage.setItem(q,v) )
      localStorage.setItem('checkedUtm', true);
  }
  const referrer = document.referrer;
  if (!referrer) {
    return;
  }

  const utmSource = localStorage.getItem('utm_source');
  const utmCampaign = localStorage.getItem('utm_campaign');
  if(utmSource || utmCampaign){
    return;
  }

  const referrers = {
    'yandex' : ['ya.ru', 'yandex.ru'],
    'google' : ['google.ru', 'google.com'],
    'mail' : ['mail.ru', 'go.mail.ru'],
    'rambler' : ['rambler.ru'],
  };

  const ref = Object.fromEntries(
    Object.entries(referrers)
    .filter(([q, v]) => v.some((k) => referrer.includes(k)))
  );
  const [source] = Object.keys(ref);
  if (source){
    localStorage.setItem('utm_source', source);
    localStorage.setItem('utm_campaign', 'organic');
  }

}
