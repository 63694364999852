import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b892377 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _11e79ca1 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _11cde80e = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _c681274e = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _d66aed2c = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _164afa7c = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _7050bc41 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _23c0ca26 = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _7111fd1c = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _80155ef6 = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _97458962 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _d3bf7e98 = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _4ed77ece = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _58624348 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _508d8aac = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _6b84e807 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _ac6b8ef0 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _ed48e6dc = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _2b892377,
    name: "About"
  }, {
    path: "/Banks",
    component: _11e79ca1,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _11cde80e,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _c681274e,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _d66aed2c,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _164afa7c,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _7050bc41,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _23c0ca26,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _7111fd1c,
    name: "Privacy"
  }, {
    path: "/Reviews",
    component: _80155ef6,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _97458962,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _d3bf7e98,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _4ed77ece,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _58624348,
    name: "index"
  }, {
    path: "/:category",
    component: _508d8aac,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _6b84e807,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _ac6b8ef0,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _ed48e6dc,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
